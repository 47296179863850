import '../styles/login.css'
import LoginWithEmail from "./LoginWithEmail";
import LoginWithSocial from "./LoginWithSocial";

export default function Login() {
    return (
        <div>
            <div className="login_with_email"><LoginWithEmail /></div>
            <div className="login_with_social"><LoginWithSocial /></div>
        </div>
    );
}