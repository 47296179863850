import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NoMatch from './components/NoMatch';
import { AuthProvider } from './common/auth';
import { ProtectedRoute } from './common/auth';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import JobCreatorList from './components/feed/job-creator-list';
import JobPage from './components/feed/job-responder-list';
import { UserProvider } from './common/user';
import useScript from 'react-script-hook';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import VerifyAuthLink from './components/VerifyAuthLink';
import Login from './components/Login';

const REACT_GA_TRACKING_ID = 'G-3W88MPK6TC';
ReactGA.initialize(REACT_GA_TRACKING_ID);

function App(props) {
  useScript({
    src: 'https://cdn.linkmink.com/lm-js/2.3.1/lm.js',
    onload: () => {
      if (typeof LinkMink === "function") {
        window.LinkMink('pub_live_zefYZjq9jpXGGPz10WPj').initTracking().then();
      }
    },
    checkForExisting: true,
  });

  useEffect(() => {
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <JobCreatorList />
              </ProtectedRoute>
            } />
            <Route path="/jobs" element={<ProtectedRoute> <JobPage /> </ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path='/verify/:token' element={<VerifyAuthLink />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
