export default function Terms() {
    return (
    <div style={{ padding: 50, letterSpacing: 1}}>
      <p>
        Your acceptance and acknowledgement of these terms and conditions (“terms”) is a 
        legal agreement between you (“end user, your or you”) and COMPANY. The terms governs 
        your use of the Genreply Platform. This document is an electronic record and does 
        not require any physical or digital signatures.
      </p>
      <p>“COMPANY, US, OUR OR WE” as used herein means Genreply.</p>
      <p>
        "Genreply Platform" means and refers to company’s http://www.genreply.com/ website("Site").
      </p>
      <h3>Limitation of Liability</h3>
      <p>We provide the service as is, and we make no promises or guarantees about it. </p>
      <p>You understand and agree that we will not be liable to You or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however they arise.</p>
      <h3>Indemnity</h3>
      <p>
        To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Genreply Platform, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive these Terms of Service and your use of the Service.
      </p>
      <h3>Refund Policy</h3>
      <p>
      Once the subscription is purchased, it cannot be cancelled. You can cancel auto-renewal of your GenReply membership at any time, and you will continue to have access to the Genreply premium membership through the end of your subscription period. To cancel auto-renewal, please visit <a href="https://billing.stripe.com/p/login/fZe171frZ8M41RCbII">here</a>
      </p>
      <h3>Your acceptance of these terms</h3>
      <p>
      You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service. In any case, you affirm that you are over the age of 13, as the Service is not intended for children under 13. If you are under 13 years of age, then please do not use the Service. There are lots of other great web sites for you. Talk to your parents about what sites are appropriate for you.

We provide these Terms of Service with our Service so that you know what terms apply to your use. You acknowledge that we have given you a reasonable opportunity to review these Terms of Service and that you have agreed to them.
      </p>
      <h3>Grievance redressal</h3>
      <u>Customer Support</u>
      <p>You can contact our customer service team at team@genreply.com with your queries / complaints.</p>
      <h3>Governing Laws and Disputes</h3>
      <p>The Users expressly agree that the Terms of Use, Privacy Policy and any other agreements entered between the Company and User(s) are governed by the laws, rules and regulations of India, and that the Courts at Bangalore shall have exclusive jurisdiction over any disputes arising between the Parties.</p>
    </div>);
}