import React, { useState, useEffect } from "react";
import { useStateWithCallback } from "../util";
import JobCreatorItem, { JOB_STATUS } from "../../common/job-creator-item";
import Introduction from "../introduction";
import Header from "../../common/header";
import ReactPixel from 'react-facebook-pixel';

import '../../styles/job-creator-list.css';
import { useUser } from "../../common/user";
import JobCreationInput from "../job-creation-input";
import CreditsBanner from "../credits-banner";
import Lottie from 'lottie-react';
import genReplyLoader from '../../assets/lottie/genreply-loader.json';
import { useSearchParams } from "react-router-dom";
import { isPurchasePixelLoggedCookie, setPurchasePixelLoggedCookie } from "../../common/cookie";
import ReactGA from "react-ga4";
import S3Upload from "../../util/s3-upload";

const JOBS_POLLING_INTERVAL = 5000;

const JobCreatorList = () => {
  const [job, setJob] = useState("");
  const [jobs, setJobs] = useStateWithCallback({});
  const [isQuerying, setIsQuerying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {credits, setCredits} = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const endOfListRef = React.useRef(null);
  let jobPollerRef = React.useRef(null);

  const pollJobsFetcher = () => {
    if (jobPollerRef) clearTimeout(jobPollerRef);
    jobPollerRef = setTimeout(() => {
      fetchJobs();
    }, JOBS_POLLING_INTERVAL);
  };

  const prepareJob = (job, aspectRatio = null, imageBlob = null) => {
    job.trim();
    
    ReactGA.event('search', {
      search_term: job
    });

    if (job === "") {
      alert("Please enter a description");
      return;
    }
    ReactPixel.trackCustom('JobSubmit');
    ReactGA.event('job_submit');

    if (aspectRatio != null){
      job = job + " --ar " + aspectRatio;
    }
    setIsQuerying(true);

    if (imageBlob != null) {
      fetch(`${process.env.REACT_APP_API_URL}/presigned/image/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then((data) => {
        S3Upload.execute(
          data['url'],
          data['image_path'],
          imageBlob,
          (path) => {
            job = job + "  " + `https://genreply.s3.ap-south-1.amazonaws.com/${path}`;
            createJob(job);
          },
          (error) => {
            setIsQuerying(false);
            console.log("Error: ", error);
            alert("Some error occurred. Please try again!")
          },
          "image/jpeg"
        );
      })
      .catch((error) => {
        setIsQuerying(false);
        if (error instanceof TypeError) {
          console.log("Error: " + error.message);
          return;
        }
        if (error instanceof Promise) {
          error?.json().then((error) =>
            console.log("Error: " + error.status + '\n' + error.message)
          );
          return;
        }
        console.log("Error: ", error);
      });
    } else {
      createJob(job);
    }
    
  }

  const createJob = (jobDescription) => {
    fetch(`${process.env.REACT_APP_API_URL}/job/`, {
      method: "POST",
      credentials: 'include',
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          "description": jobDescription
      }),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      const tempJobs = {...jobs};
      tempJobs[data['job']['id']] = data['job'];
      setJobs(
        tempJobs, 
        () => {
          fetchCredits();
        }
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      setIsQuerying(false);
    })
    .catch(error => {
      setIsQuerying(false);
      if (error instanceof TypeError) {
        alert("Error: " + error.message);
        return;
      }
      error?.json().then((error) => 
        alert("Error: " + error.message)
      );
    });
  }

  const fetchJobs = (callback = undefined) => {
    fetch(`${process.env.REACT_APP_API_URL}/jobs`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
          "user_id": localStorage.getItem('userId'),
      }),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      setJobs(
        data['jobs'],
        () => {
          if (callback) callback();
        }
      );
      pollJobsFetcher();
    })
    .catch(error => {
      pollJobsFetcher();
      if (error instanceof TypeError) {
        alert("Error: " + error.message);
        return;
      }
      error?.json().then((error) => 
        console.error("Error: " + error.status + '\n' + error.message)
      );
    });
  }

  const fetchCredits = () => {
    fetch(`${process.env.REACT_APP_API_URL}/credits/`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      setCredits(data['credits']);
    })
    .catch((error) => {
      if (error instanceof TypeError) {
        alert("Error: " + error.message);
        return;
      }
      error?.json().then((error) => 
        alert("Error: " + error.status + '\n' + error.message)
      );
    }) 
  }
  
  const logFacebookPixelForPurchase = (stripe_checkout_session_id) => {
    fetch(`${process.env.REACT_APP_API_URL}/stripe/checkout-session-info/`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'checkout_session_id': stripe_checkout_session_id,
      }),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      if (data['status'] === 'complete') {
        if (!isPurchasePixelLoggedCookie()) {
          ReactPixel.track('Purchase', {
            'currency': data['currency'],
            'value': data['amount'],
          });
          ReactGA.event('purchase', {
            transaction_id: stripe_checkout_session_id,
            items: [{
              item_id: 'unlimited_credits',
            }],
            currency: data['currency'],
            value: data['amount'],
          });
          setPurchasePixelLoggedCookie();
        }
      }
    })
    .catch((error) => {
      error?.json().then((error) => 
        alert("Error: " + error.status + '\n' + error.message)
      );
    });
  }

  useEffect(() => {
    fetchJobs(() => {
      setIsLoading(false);
    });
    fetchCredits();
  }, []);

  useEffect(() => {
    const success = searchParams.get('success');
    if (success === 'true') {
      const stripeCheckoutSessionid = searchParams.get('session_id');
      if (!!stripeCheckoutSessionid) {
        logFacebookPixelForPurchase(stripeCheckoutSessionid);
      }
    }
    setSearchParams(params => {
      params.delete('session_id');
      params.delete('success');
      params.delete('cancel');
      return params;
    });
  }, [searchParams]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      createJob();
    }
  }
  
  return (
    <div>
      <Header />
      {isLoading && <div><div className="job-creator-list-loader-container"> <Lottie animationData={genReplyLoader} /> </div></div>}
      {!isLoading &&
        <div>
          <JobCreationInput
            setJob={setJob} 
            handleKeyDown = {handleKeyDown}
            isLoading = {isQuerying}
            jobName = {job}
            prepareJob = {prepareJob}
          />
          <div className="job-creator-list-container">
            {Object.keys(jobs).length === 0 ?
              <Introduction /> :
              <div className={`job-creator-results-container${credits <= 0 ? "-with-banner" : ""}`}>
                {
                  Object.keys(jobs).toReversed().map((job_id, index) => 
                    <JobCreatorItem key={job_id} job={jobs[job_id]} />
                  )
                }
              </div>
            }
            <div ref={endOfListRef} />
            <CreditsBanner/>
          </div>
        </div>
      }
    </div>
  )

}

export default JobCreatorList;