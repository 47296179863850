import React, { useEffect, useRef } from "react";
import Lottie from 'lottie-react';
import blackDotsLoader from '../assets/lottie/4-black-dots-loader.json';
import '../styles/image-with-loader.css';

const ImageWithLoader = (props) => {
  const imgRef = useRef(null);
  const tempRef = useRef(null);

  useEffect(() => {
    var imgNode = imgRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = imgNode;
          img.onload = () => {
            props.onLoad();
          };
          img.src = props.src;
          observer.unobserve(img);
        }
      });
    }, {
      threshold: [0,1]
    });

    if (tempRef.current) {
      observer.observe(tempRef.current);
    }

    return () => {
      if (tempRef.current) {
        observer.unobserve(tempRef.current);
      }
    };
  });

  return (
    <div className={`img-with-loader-container${props.renderImage ? "" : "-loading"}`}>
      <div className={`img-with-loader-lottie-container${props.renderImage ? "-hidden" : ""}`}> 
        <Lottie animationData={blackDotsLoader}/> 
      </div>
      <img 
        className={`img-with-loader-image${props.renderImage ? "" : "-hidden"} ${props.blur ? "blur" : ""}`}
        id={props.src}
        ref={imgRef}
        alt=""
      />
      <div className="img-with-loader-hidden-div" ref={tempRef} />
    </div>
  )
}

export default ImageWithLoader;