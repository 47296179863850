import React, { useState } from "react";
import '../styles/job-responder-item.css';
import { JOB_STATUS } from "./job-creator-item";
import ImageUtil from "../util/image-util";

export const JOB_EVENT_TYPE = {
  PICKED: 0,
  DROPPED: 1,
  COMPLETED: 2,
};
const RESPONSE_COUNT = 4;

const JobResponderItem = (props) => {
  const { job } = props;
  const [links, setLinks] = useState(Array.from({length: RESPONSE_COUNT}).map(el => ""));

  const handleJobAssignment = (value) => {
    fetch(`${process.env.REACT_APP_API_URL}/job/`, {
      method: "PUT",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      props.updateJob(data['job']);
    })
    .catch(error => {
      if (error instanceof TypeError) {
        alert("Error: " + error.message);
        return;
      }
      error?.json().then((error) => 
        alert("Error: " + error.status + '\n' + error.message)
      );
    });
  }

  async function isImgUrl(url) {
    try {
      const response = await fetch(url, {method: 'HEAD'});
      return response.headers.get('Content-Type').startsWith('image');
    } catch (error) {
      return false;
    }
  }


  const handleSubmit = async () => {
    for (let i = 0; i < links.length; i++) {
      links[i] = links[i].trim();
      if (!links[i]) {
        alert("Please fill in all links");
        return;
      }
    }

    for (let i = 0; i < links.length; i++) {
      if (links[i] === links[i + 1]) {
        alert("Please input unique image links");
        return;
      }
    }

    for (let i = 0; i < links.length; i++) {
      const isImg = await isImgUrl(links[i]);
      if (!isImg) {
        alert("Please input valid image links");
        return;
      }
    }

    handleJobAssignment({
      job_id: job.id,
      job_status: JOB_STATUS.COMPLETED,
      value: links
    });
  
  }

  return (
    <div>
      {job?.status === JOB_STATUS.COMPLETED
        || job?.status === JOB_STATUS.REJECTED
        || (job?.status === JOB_STATUS.IN_PROGRESS 
            && job?.event?.user_id != localStorage.getItem('userId'))
       ? null
       : <div className="job-responder-item-container">
          <div className="job-responder-item-description-container">
            <div 
              className="job-responder-item-description" 
              id={`job-responder-item-description-${job?.id}`}>
              {job.description}
            </div>
            <img 
              className="description-copy-to-clipboard-icon"
              onClick={() => {
                let text = document.getElementById(`job-responder-item-description-${job?.id}`).innerText;
                navigator.clipboard.writeText(text);
              }}
              src={ImageUtil.getS3ImageUrl('web/icons/copy_to_clipboard_icon.svg')} 
              alt="Copy to Clipboard Icon" />
          </div>
          <div className="job-responder-item-horizontal-line" />
          <div className="job-responder-item-input-container">
            {
              job?.status === JOB_STATUS.IN_PROGRESS &&
              links.map((link, index) =>
                <input 
                  key={index}
                  className="job-responder-item-input-bar"
                  type="text" 
                  value={links[index]} 
                  placeholder="Input link here"
                  onChange={e => {
                    links[index] = e.target.value; 
                    setLinks([...links])
                  }} />
              )
            }
          </div>
          {job?.status === JOB_STATUS.CREATED
            &&  <div 
                  className="job-accept-button"
                  onClick={() => {
                    handleJobAssignment({
                      job_id: job.id,
                      job_status: JOB_STATUS.IN_PROGRESS
                    });
                  }}>
                    Grab
                </div>
            }
            {job?.status === JOB_STATUS.IN_PROGRESS 
              && <div className="job-responder-action-container"> 
                  <div 
                    className="job-responder-item-flag-inappropriate"
                    onClick={() => {
                      handleJobAssignment({
                        job_id: job.id,
                        job_status: JOB_STATUS.REJECTED
                      });
                    }} > Invalid </div>
                  <div className="job-responder-close-submit-container">
                    <div 
                      className="job-responder-close-button"
                      onClick={() => {
                        handleJobAssignment({
                          job_id: job.id,
                          job_status: JOB_STATUS.CREATED
                        });
                      }}> Leave </div>
                    <div 
                      className="job-responder-submit-button"
                      onClick={() => {
                        handleSubmit();
                      }}> Submit </div>
                  </div>
                </div>
            }
        </div>
      }
    </div>
  );
}

export default JobResponderItem;
