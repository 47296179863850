import React from 'react';
import {
  Navigate,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userId, setUserId] = React.useState(null);
  const previusUserId = localStorage.getItem('userId');
  if (!userId && previusUserId) {
    setUserId(previusUserId);
  }

  const handleLogin = (token) => {
    console.log("Logging in..." + token);
    setUserId(token);
    localStorage.setItem('userId', token);
    const origin = location.state?.from?.pathname;
    
    if (origin === undefined || origin.includes("/login")) {
      navigate({pathname: "/", search: location.search});
      return;
    }

    console.log("Logging in..." + origin);
    navigate({pathname: origin, search: location.search});
  };

  const handleLogout = () => {
    setUserId(null);
  };

  const value = {
    userId,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContext = React.createContext(null);
export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const ProtectedRoute = ({ children }) => {
  const { userId } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (!userId) {
    return <Navigate to={{pathname: "/login", search: `?${searchParams}`}} replace state={{ from: location }} />;
  }

  return children;
};


    
