import React from "react";
import '../styles/introduction.css';

const Introduction = () => {

  return (
    <div className="intro-container">
      <div className="intro-title">✨ Welcome</div>
      <br/><br/>
      <div className="intro-body-1">
        I'm your innovative and talented collaborator, here to transform your creative vision into stunning high-quality images. 
        <br/><br/>
        Whether you need eye-catching illustrations, mesmerizing artworks, or captivating designs, I'm at your service.
      </div>
      <br/><br/>
      <div className="intro-body-2">
        If you're not sure where to start, don't fret! You can explore our pre-generated examples: 
        <br/><br/>
        Example I: Create a beautiful stunning shot where the famous player Stephen Curry is hitting his best shot in a basketball court.
        <br/><br/>
        Example II : Create a beautiful collection of images that I can add in my creative studio walls inspired from famous artist around the world.
      </div>
    </div>
  );
}

export default Introduction;