export default function Privacy() {
    return (<div style={{ padding: 50, letterSpacing: 1}}>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy governs the manner in which Genreply 
        collects, uses, maintains and discloses information collected from users (each, a "User")
        of the Genreply http://www.genreply.com/ website("Site").
      </p>

      <h3>Personal identification information</h3>
      <p>
        We may collect personal identification information from Users in a variety 
        of ways, including, but not limited to, when Users visit our website, register 
        on the website, and in connection with other activities, services, features or 
        resources we make available on our website. Users may be asked for, as 
        appropriate, name, email address. We will collect personal identification 
        information from Users only if they voluntarily submit such information to us. 
        Users can always refuse to supply personally identification information, 
        except that it may prevent them from engaging in certain website related 
        activities.
      </p>

      <h3>Non-personal identification information</h3>
      <p>
        We may collect non-personal identification information about Users whenever 
        they interact with our website. Non-personal identification information may 
        include the browser name, the type of device and technical information about 
        Users means of connection to our website, such as the operating system and the 
        Internet service providers utilized and other similar information.
      </p>

      <h3>How we use collected information</h3>
      <p>
        Genreply may collect and use Users personal information 
        for the following purposes:
      </p>
      <ul>
        <li>
          <i>To run and operate our Website</i><br/>
          We may need your information to display content on the Website correctly.
        </li>
        <li>
          <i>To personalize user experience</i><br/>
          We may use information in the aggregate to understand how our Users as 
          a group use the services and resources provided on our Website.
        </li>
        <li>
          <i>To send periodic emails</i><br/>
          We may use the email address to send User information and updates pertaining 
          to their order. It may also be used to respond to their inquiries, questions, 
          and/or other requests. 
        </li>
      </ul>

      <h3>How we protect your information</h3>
      <p>
        We adopt appropriate data collection, storage and processing practices and 
        security measures to protect against unauthorized access, alteration, disclosure 
        or destruction of your personal information and data stored on our Server.
      </p>

      <h3>Sharing your personal information</h3>
      <p>
        We do not sell, trade, or rent Users personal identification information to 
        others. We may share generic aggregated demographic information not linked to 
        any personal identification information regarding visitors and users with our 
        business partners, trusted affiliates and advertisers for the purposes 
        outlined above. 
      </p>

      <h3>Retention of your personal information</h3>
      <p>
        Your data is retained with Genreply as long as you have an active account on
        the Company Systems.You can request that we delete some or all of your
        Personal Data from our systems by emailing us at <a class="link" href="mailto:team@genreply.com">team@genreply.com</a>.
        Once your data is deleted, you will no longer have an account with Genreply.
      </p>

      <h3>Changes to this privacy policy</h3>
      <p>
        Genreply has the discretion to update this privacy policy at any time. When we 
        do, we will post a notification on the main page of our Site. We encourage 
        Users to frequently check this page for any changes to stay informed about 
        how we are helping to protect the personal information we collect. 
        You acknowledge and agree that it is your responsibility to review this privacy 
        policy periodically and become aware of modifications.
      </p>

      <h3>Your acceptance of these terms</h3>
      <p>
        By using Website, you signify your acceptance of this policy. 
        If you do not agree to this policy, please do not use our Website. 
        Your continued use of the Website following the posting of changes to this policy 
        will be deemed your acceptance of those changes. 
      </p>

      <h3>Contacting us</h3>
      <p>
        If you have any questions about this Privacy Policy, the practices of this Website, 
        or your dealings with this Website, please email us at <a class="link" href="mailto:team@genreply.com">team@genreply.com</a>.
      </p>
      <p>This document was last updated on July 28, 2023</p>
    </div>);
}