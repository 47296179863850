import React, { useEffect, useState } from 'react';
import '../styles/earning.css';
import ImageUtil from "../util/image-util";


const Earnings = (props) => {
    const [earnings, setEarnings] = useState(0);

    useEffect(() => {
        fetchEarning();
    }, []);

    const fetchEarning = () => {
        fetch(`${process.env.REACT_APP_API_URL}/earnings`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({user_id: localStorage.getItem('userId')})},
            )
            .then(response => response.json())
            .then(data => {
                setEarnings(data["earnings"]);
            })
            .catch(error => {
                console.log(error);
            })
    }
    
    return (
        <div className={"total-payout-amount-container"}>
        <img src={ImageUtil.getS3ImageUrl("web/icons/jackpot.svg")}/>
        <div className={"row-flex"}>
            <div className={"total-payment-amount"}>
                ₹{earnings}
            </div>
            <div className={"total-payment-amount-descritption"}>
                Total Earning
            </div>
        </div>
    </div>
    );
}

export default Earnings;