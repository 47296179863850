import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from 'react-facebook';
import ReactPixel from 'react-facebook-pixel';

const root = ReactDOM.createRoot(document.getElementById('root'));
const facebookAppId = '653904020036647';
const facebookPixelId = '1477681922771036';
ReactPixel.init(facebookPixelId);
ReactPixel.pageView();
root.render(
  <GoogleOAuthProvider clientId="779682486344-5ggv8kl4chnglt0fhrhe24r37rfss75m.apps.googleusercontent.com">
    <FacebookProvider appId={facebookAppId}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </FacebookProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
