export default class S3Upload {
  static execute(url, path, data, successCallback, errorCallback, contentType = undefined) {
    var options = {
      method: "PUT",
      body: data,
    };
    if (contentType) {
      options['headers'] = { 'Content-Type': contentType };
    }

    fetch(url, options)
    .then((response) => {
      if (response.ok) {
        successCallback(path);
        return;
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      if (error instanceof TypeError) {
        errorCallback(error);
        return;
      }
      if (error instanceof Promise) {
        error?.json().then((error) => 
          errorCallback(error)
        );
        return;
      }
      errorCallback(error);
    }
    );
  }
}
