import React from 'react';
import '../styles/header.css'

const MANAGE_SUBSCRIPTION_URL = 'https://billing.stripe.com/p/login/fZe171frZ8M41RCbII';

const Header = (props) => {

  return (
    <div className="header-items-wrapper">
      <div className="header-items-row">
        <div className="header-title header-title-logo"></div>
        <div className="header-manage-subscription">
          <a href={MANAGE_SUBSCRIPTION_URL} rel='noreferrer' target='_blank'>Manage Subscription</a>
        </div>
      </div>
      <div className='header-hr-line'/>
    </div>
  );
}

export default Header;
