import React from "react";
import '../styles/circular-progress-bar.css';

const CircularProgressBar = (props) => {

  return (
    <div className={"progress-circle" + (props.loaderPercentage >= 50 ? " over50" : "")}>
      <span>{`${props.loaderPercentage}%`}</span>
      <div className="left-half-clipper">
        <div className="first50-bar"></div>
        <div 
          className="value-bar" 
          style={{transform: `rotate(${props.loaderPercentage * 3.6}deg)`}}>
        </div>
      </div>
    </div>
  )
}

export default CircularProgressBar;