import { ColorRing } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useAuth } from '../common/auth';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

export default function VerifyAuthLink() {
    const {token} = useParams();
    const { onLogin } = useAuth();
    const [invalidLink, setInvalidLink] = useState(false);

    useEffect(() => {
        verify();
    }, [])

    function verify() {
        if (!token) {
            alert(`Invalid Link ${token}`);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/auth-link-verify/${token}`, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
          })
          .then((response) => response.json())
          .then((data) => {
            onLogin(data['user']['id']);
            ReactPixel.track('CompleteRegistration');
            ReactGA.event('login', {method: 'email'});
          })
          .catch(function(error) {
              setInvalidLink(true);
              ReactGA.event('auth_link_expired');
          });
    }

    return (
        <div style={{position: "absolute", width: "100%", top: "50%", textAlign: "center"}}>
            {!invalidLink &&
              <ColorRing
                visible={true}
                height="60"
                width="60"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#000000', '#000000', '#000000', '#000000', '#000000']}
              />
            }
            {invalidLink && <div style={{color: "black"}}>Invalid Link</div>}
        </div>
    );
}