
export const COOKIE_KEYS = {
    purchase_pixel_logged: 'purchase_pixel_logged'
}

const setCookie = (key, value, days) => {
    let cookie = `${key}=${value}`;
    if (days) {
        const date = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
        cookie = `${cookie};expires=${date.toUTCString()};`;
    }
    console.log(cookie);
    document.cookie = `${cookie};path=/;domain=.genreply.com`;
}
  
const getCookie = (key) => {
    var matches = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
    return matches ? matches.pop() : undefined;
}

export const isPurchasePixelLoggedCookie = () => {
    return !!getCookie(COOKIE_KEYS.purchase_pixel_logged);
}

export const setPurchasePixelLoggedCookie = () => {
    setCookie(COOKIE_KEYS.purchase_pixel_logged, 'true', 1);
}
