import React, { createContext, useContext, useState, useMemo } from 'react';

export const UserProvider = ({ children }) => {
    const [credits, setCredits] = useState();

    const value = useMemo(() => ({
        credits,
        setCredits
    }), [credits, setCredits]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const UserContext = createContext(null);

export const useUser = () => {
  return useContext(UserContext);
};


    
