import React, { useState } from "react";
import '../styles/introduction.css';
import ImageUtil from "../util/image-util";
import { useStateWithCallback } from "./util";
import { isMobile } from "./util";

const ASPECT_RATIOS = {
  "1024x1024": "1:1",
  "1152x896": "5:4",
  "1216x832": "3:2",
  "1344x768": "16:9",
  "1536x640": "21:9",
  "640x1536": "9:21",
  "768x1344": "9:16",
  "832x1216": "2:3",
  "896x1152": "4:5"
}

const ASPECT_RATIO_MAP = {
  "1024x1024": {width: 1024, height: 1024},
  "1152x896": {width: 1152, height: 896},
  "1216x832": {width: 1216, height: 832},
  "1344x768": {width: 1344, height: 768},
  "1536x640": {width: 1536, height: 640},
  "640x1536": {width: 640, height: 1536},
  "768x1344": {width: 768, height: 1344},
  "832x1216": {width: 832, height: 1216},
  "896x1152": {width: 896, height: 1152}
}

const JobCreationInput = (props) => {
  const {setJob, handleKeyDown, isLoading, jobName, prepareJob} = props;

  const [aspectRatio, setAspectRatio] = useStateWithCallback("1024x1024");
  const [imageBlob, setImageBlob] = useState(null);
  const [isInputTrayOpen, setIsInputTrayOpen] = useState(false);

  const selectImage = (e, callback) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader  = new FileReader();
    reader.readAsDataURL(file);
    if (e.target.files.length === 0) {
      return;
    }
    reader.onloadend = callback;
  }

  const defaultImageCallback = (e) => {
    var image = new Image();
    image.src = e.target.result;
    resizeImage(image);
  }

  const resizeImage = (imageElement) => {
    imageElement.onload = function(ev) {
      var resizedImage = ImageUtil.getResizedImage(
        imageElement, 
        ASPECT_RATIO_MAP[aspectRatio].width, 
        ASPECT_RATIO_MAP[aspectRatio].height
      );
      setImageBlob(resizedImage.imageFile);
    }
  }
  
  return (
    <div>
    <div className={`job-creation-input-container ${!isInputTrayOpen ? "reduce-input-container-height" : ""}`} id="this-id">
      { isInputTrayOpen &&
        <div 
          className="job-creation-input-arrow-down-wrapper"
          onClick={() => setIsInputTrayOpen(false)}>
        <img 
          alt=""
          src={ImageUtil.getS3ImageUrl('web/icons/keyboard_arrow_down.svg')}
          className="job-creation-input-arrow-down"
        />
      </div>
      }
      { (!isMobile || (isMobile && isInputTrayOpen)) &&
        <div>
          <div className="job-creation-label">Prompt</div>
          <div className={`job-creation-input-wrapper${isLoading ? "-disabled" : ""}`}>
            <textarea
              className="job-creation-input-bar"
              type="text"
              placeholder="Click here to write description"
              value={jobName}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              onChange={(e) => setJob(e.target.value)}
            />
          </div>
          <div className="job-creation-image-label-wrapper">
            <div className="job-creation-label">Image <span>(optional)</span></div>
            { imageBlob !== null &&
              <img 
                alt="" 
                src={ImageUtil.getS3ImageUrl('web/icons/dustbin_outlined_black.svg')}
                onClick={() => setImageBlob(null)} />
            }
          </div>
          { imageBlob === null &&
            <label className="job-creation-input-image-upload-label">
              <img
                  className="job-creation-input-image-edit-icon"
                  alt=""
                  src={ ImageUtil.getS3ImageUrl('web/icons/filled_plus_icon.svg')}
              />
              Upload an image to create variations
                <input
                  className="job-creation-input-image-upload"
                  accept="image/*"
                  type="file"
                  onChange={(e) => selectImage(e, defaultImageCallback)}
                />
            </label>
          }
          { imageBlob !== null &&
            <div className="job-creation-input-preview-image-container">
              <img 
                className="job-creation-input-preview-image"
                alt=""
                src={URL.createObjectURL(imageBlob)}
              />
            </div>
          }
          <div className="job-creation-label">Aspect Ratio</div>
          <div className="job-creation-input-aspect-ratio-map">
            {
              Object.keys(ASPECT_RATIOS).map((_aspectRatio, index) =>
                <div
                  key={_aspectRatio} 
                  className={`job-creation-input-aspect-ratio${_aspectRatio === aspectRatio ? "-selected" : ""}`}
                  onClick={() => 
                    setAspectRatio(
                      _aspectRatio,
                      () => {
                        if (imageBlob != null) {
                          var image = new Image();
                          image.src = URL.createObjectURL(imageBlob);
                          resizeImage(image);
                        }
                      }
                    )
                  }>
                  {ASPECT_RATIOS[_aspectRatio]}
                </div>
              )
            }
          </div>
      </div>
    }
    <div 
      className="job-creation-input-button-container"
      onClick={() => {
        if (isMobile && !isInputTrayOpen) {
          setIsInputTrayOpen(true);
        } else {
          prepareJob(jobName, ASPECT_RATIOS[aspectRatio], imageBlob != null ? imageBlob : null);
        }
      }}>
      <div className="job-creation-input-button-text">
      {!isMobile || (isMobile && isInputTrayOpen) ? "GENERATE IMAGE" : "ENTER PROMPT"}
      </div>
    </div>
    </div>
    </div>
  );
}

export default JobCreationInput;