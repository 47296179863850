import React, { useState, useEffect } from "react";
import '../styles/job-creator-item.css';
import ImageWithLoader from "./image-with-loader";
import CircularProgressBar from "./circular-progress-bar";

export const JOB_STATUS = {
  CREATED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  REJECTED: 3,
}

export const JOB_STATUS_TEXT = {
  [JOB_STATUS.CREATED]: "Waiting to start...",
  [JOB_STATUS.IN_PROGRESS]: "Started...",
  [JOB_STATUS.REJECTED]: "Rejected!",
}

const dummyUrlList = ["", "", "" , ""]
const SIXTY_SECONDS = 60;
const LOADER_PROGRESS_TIMEOUT = 1000;

const JobCreatorItem = (props) => {
  const { job } = props;
  const urlList = job?.event?.value || dummyUrlList;
  const [isImageLoaded, setIsImageLoaded] = useState(Array.from({length: urlList.length}).map(el => false));
  const [loaderTimeElapsed, setLoaderTimeElapsed] = useState(0);
  let loaderTimeout = null;

  useEffect(() => {
    handleLoaderProgress();
    return () => {
      clearTimeout(loaderTimeout);
    }
  });

  useEffect(() => {
    if (areAllImagesLoaded()) {
      clearTimeout(loaderTimeout);
    }
  }, [isImageLoaded]);
  
  const handleLoaderProgress = () => {
    loaderTimeout = setTimeout(() => {
      setLoaderTimeElapsed((Math.min(loaderTimeElapsed + 1, SIXTY_SECONDS - 1)));
    }, LOADER_PROGRESS_TIMEOUT);
  }

  const areAllImagesLoaded = () => {
    return isImageLoaded.filter(el => el === false).length === 0;
  }

  return (
    <div className="job-creator-item-container">
      <div className="job-creator-item-description"> {job?.description} </div>
      <div className="job-creator-item-wrapper">
        {job?.status in [JOB_STATUS.IN_PROGRESS, JOB_STATUS.CREATED] &&
          !areAllImagesLoaded() &&
          <div className="job-creator-item-progress-bar-container">
            <CircularProgressBar loaderPercentage={Math.round((loaderTimeElapsed / SIXTY_SECONDS) * 100)} />
          </div>
        }
        {job?.status !== JOB_STATUS.REJECTED &&
          <div className="job-response-img-container">
            {
              urlList.map((result, index) => 
                <a key={index} className="job-response-img-wrapper" href={result} target="_blank" rel="noreferrer">
                  <ImageWithLoader 
                    src={result} 
                    index={index}
                    renderImage={areAllImagesLoaded()}
                    onLoad={() => {
                      isImageLoaded[index] = true;
                      setIsImageLoaded([...isImageLoaded]);
                    }} />
                </a>
              )
            }
          </div>
        }
      </div>
      {job?.status === JOB_STATUS.REJECTED && <div className={`job-started-text status-${job?.status}-text`}> {JOB_STATUS_TEXT[job?.status]} </div>}
    </div>
  );
}

export default JobCreatorItem;