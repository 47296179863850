import { useRef, useState, useCallback, useEffect } from 'react';

export const useStateWithCallback = initialValue => {
  const callbackRef = useRef(null);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = useCallback(
    (newValue, callback = undefined) => {
      if (callback) callbackRef.current = callback;
      return setValue(newValue);
    },
    [],
  );

  return [value, setValueWithCallback];
};

export const getLinkMinkReferralData = () => {
  try {
    if (typeof LinkMink === "function") {
      return window.LinkMink('pub_live_zefYZjq9jpXGGPz10WPj').getReferralData()
    }
  } catch (error) {
    console.log(error);
  }

  return undefined;
}

export const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;