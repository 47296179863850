import '../styles/login-with-email.css'

import React, { useState } from "react";
import ImageUtil from '../util/image-util';
import {getLinkMinkReferralData} from './util';
import ReactGA from 'react-ga4';
import { ColorRing } from 'react-loader-spinner'

export default function LoginWithEmail() {
  const [linkSent, setLinkSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [email, setEmail] = useState("");

  function sendLinkToEmail(email) {
      let data = {"email": email}
      let referralData = getLinkMinkReferralData();
      if (referralData !== undefined) {
        data['lmref'] = referralData;
      }

      const login_job_id = localStorage.getItem("login_job_id");
      if (login_job_id) {
        data['job_id'] = login_job_id;
      }

      ReactGA.event('auth_link_requested');
      setSendingEmail(true);
      fetch(`${process.env.REACT_APP_API_URL}/auth-link/`, {
          method: "POST",
          credentials: 'include',
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            setLinkSent(true);
            setSendingEmail(false);
            ReactGA.event('auth_link_sent');
        })
        .catch(function(error) {
            console.log(error);
            setSendingEmail(false);
        });
    }

  const is_valid_email = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  function renderCheckEmailPlaceHolder() {
    return (
        <div className="email-confirm-placeholder">
            <img className = "email-illustration" src="https://genreply.s3.ap-south-1.amazonaws.com/icons/mailbox-closed-flag-down+2.svg" />
            <div className="enter-confirmation-code">Almost there! Check your inbox</div>
            <div className="confirm-identity-text">Confirm your identity by clicking the link sent to</div>
            <div className='confirm-email-address'>{email}</div>
        </div>
    )
  }

  function renderEmailInput() {
    return (
        <div>
        <img 
        className='login-logo'
        src={ImageUtil.getS3ImageUrl('web/icons/genreply_logo.svg')}
        alt='Logo' />
      <div className='login-title'>Welcome to Genreply</div>
      <div className='login-description'>Convert your creative vision into stunning images</div>
      <div className='login-hr-line'/>
        <input
        className="email-input-box"
        type="text"
        placeholder="your.email@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)} />

      <div className="continue-button" onClick={() => {
        if (!is_valid_email(email)) {
            ReactGA.event('auth_link_invalid_email');
            return;
        }

        ReactGA.event('login_with_google_click');
        sendLinkToEmail(email);
      }} disabled={!is_valid_email(email)}>
        {!sendingEmail &&
            <div className='continue-button-text' disabled={is_valid_email(email)}>Continue</div>}
        {sendingEmail &&
            <div style={{width: "100%"}}>
              <ColorRing
                visible={true}
                height="38"
                width="38"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#fbfbfb', '#fbfbfb', '#fbfbfb', '#fbfbfb', '#fbfbfb']}
              />
            </div>}
      </div>
      <div className="terms-and-conditions">
        <span>By signing in, you agree to our  <a href="/terms/">Terms of Service</a> and  <a href="/privacy/">Privacy Policy</a></span>
      </div>
      </div>
    );
  }

  return (
    <div className="login-with-email-dialog">
      {linkSent && renderCheckEmailPlaceHolder()}
      {!linkSent && renderEmailInput()}
    </div>
  );
}