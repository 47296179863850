import '../styles/login-with-social.css'

import React from "react";
import { useAuth } from '../common/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';
import ImageUtil from '../util/image-util';
import ReactPixel from 'react-facebook-pixel';
import {getLinkMinkReferralData} from './util';
import ReactGA from 'react-ga4';

export default function LoginWithSocial() {
  const { onLogin } = useAuth();
  const { login } = useLogin();

  function signInOnServer(provider, token) {
      let data = {
        "provider": provider,
        "token": token,
      }
      let referralData = getLinkMinkReferralData();
      if (referralData !== undefined) {
        data['lmref'] = referralData;
      }

      const login_job_id = localStorage.getItem("login_job_id");
      if (login_job_id) {
        data['job_id'] = login_job_id;
      }

      ReactPixel.track('CompleteRegistration');
      ReactGA.event('login', {
        method: provider
      });
      fetch(`${process.env.REACT_APP_API_URL}/login/`, {
          method: "POST",
          credentials: 'include',
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            onLogin(data['user']['id']);
        })
        .catch(function(error) {
            console.log(error);
        });
    }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => signInOnServer("google", tokenResponse.access_token),
    onError: error => console.log(error),
  });

  async function loginWithFacebook() {
    try {
      const response = await login({scope: 'public_profile'});
      signInOnServer("facebook", response.authResponse.accessToken);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="login-dialog">
      <img 
        className='login-logo'
        src={ImageUtil.getS3ImageUrl('web/icons/genreply_logo.svg')}
        alt='Logo' />
      <div className='login-title'>Welcome to Genreply</div>
      <div className='login-description'>Convert your creative vision into stunning images</div>
      <div className='login-hr-line'/>
      <div className="login-button" onClick={() => {
        ReactGA.event('login_with_google_click');
        loginWithGoogle()
      }}>
        <img 
          className='login-social-icon'
          src={ImageUtil.getS3ImageUrl('web/icons/google_icon_circular_white_background.svg')}
          alt="google icon"/>
        <div className='login-button-text'>Continue with Google</div>
      </div>
      <div className="login-button fb-button" onClick={() => {
        ReactGA.event('login_with_facebook_click');
        loginWithFacebook();
      }}>
        <img 
          className='login-social-icon'
          src={ImageUtil.getS3ImageUrl('web/icons/facebook_icon_circular_white_background.svg')}
          alt="facebook icon"/>
        <div className='login-button-text'>Continue with Facebook</div>
      </div>
      <div className="terms-and-conditions">
        <span>By signing in, you agree to our  <a href="/terms/">Terms of Service</a> and  <a href="/privacy/">Privacy Policy</a></span>
      </div>
    </div>
  );
}