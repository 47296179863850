export default class ImageUtil {

  static getBucketName() {
    return "headfone-images"
  }

  static getPortraitAspectRatio() {
    return "?&aspect_ratio=4x5"
  }

  static getS3ImageUrl(filePath) {
    return 'https://d2tmwrdh9omrnz.cloudfront.net/' + filePath;
  }

  static getImageFilePath(imageUrl) { 
    return imageUrl.replace('https://d2tmwrdh9omrnz.cloudfront.net/', '');
  }

  static dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];
  
      return new Blob([raw], {type: contentType});
    }
  
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], {type: contentType});
  }

  static getResizedImage(image, width, height) { 
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    var dataUrl = canvas.toDataURL('image/jpeg');
    var resizedImage = ImageUtil.dataURLToBlob(dataUrl)
    return {
      imageUrl: dataUrl,
      imageFile: resizedImage
    };
  }
}