import React, { useEffect, useState } from "react";
import JobResponderItem from "../../common/job-responder-item";
import { JOB_STATUS } from "../../common/job-creator-item";

import '../../styles/job-responder-list.css';
import Earning from "../../common/earning";

const JOBS_POLLING_INTERVAL = 5000;

const JobPage = (props) => {
  const [jobs, setJobs] = useState({});
  let jobPollerRef = React.useRef(null);

  const pollJobsFetcher = () => {
    if (jobPollerRef) clearTimeout(jobPollerRef);
    jobPollerRef = setTimeout(() => {
      fetchJobs();
    }, JOBS_POLLING_INTERVAL);
  };

  const fetchJobs = () => {
    fetch(`${process.env.REACT_APP_API_URL}/jobs`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
          "status": [JOB_STATUS.CREATED, JOB_STATUS.IN_PROGRESS]
      }),
    })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((data) => {
      setJobs(data['jobs']);
      pollJobsFetcher();
    })
    .catch(error => {
      pollJobsFetcher();
      if (error instanceof TypeError) {
        alert("Error: " + error.message);
        return;
      }
      error?.json().then((error) => 
        alert("Error: " + error.status + '\n' + error.message)
      );
    });
  }

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div>
      <div className="job-responder-list-container">
        <Earning />
        {
          Object.keys(jobs).map(job_id =>
            <JobResponderItem 
              key={job_id} 
              job={jobs[job_id]} 
              updateJob={job => setJobs({...jobs, [job['id']]: job})} />
          )
        }
      </div>
    </div>
  )
}

export default JobPage;