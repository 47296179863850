import React from "react";
import '../styles/credits-exhausted-banner.css';
import { useUser } from "../common/user";
import ReactPixel from 'react-facebook-pixel';
import {getLinkMinkReferralData} from './util';
import ReactGA from "react-ga4";

const CreditsBanner = (props) => {
    const {credits} = useUser();
    const createStripeOrder = () => {
        ReactPixel.track('AddToCart');
        ReactGA.event('add_to_cart', {
            items: [{
                item_id: 'unlimited_credits',
            }]
        });
        const successUrlSearchParams = new URLSearchParams(window.location.search);
        const cancelUrlSearchParams = new URLSearchParams(window.location.search);

        successUrlSearchParams.set('success', 'true');
        cancelUrlSearchParams.set('cancel', 'true');

        let data = {
            'success_url': `${window.location.origin}?${successUrlSearchParams}&session_id={CHECKOUT_SESSION_ID}`,
            'cancel_url': `${window.location.origin}?${cancelUrlSearchParams}`,
        }

        let referralData = getLinkMinkReferralData();
        if (referralData !== undefined) {
            data['lmref'] = referralData;
        }
        
        fetch(`${process.env.REACT_APP_API_URL}/stripe/checkout/`, {
          method: 'post',
          credentials: 'include',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(data)
        })
        .then(function(response) {
          return response.json();
        })
        .then(body => {
            window.location.href = body.stripe_checkout_url
        });
    }

    return (
        credits <= 0 ?
        <div className="credits-exhausted-banner-container">
            <div className={"credits-exhausted-banner-wrapper"}>
                <div className="credits-exhausted-banner-title">
                    Get Unlimited Credits for first month at $10/Month
                </div>
                <div className="credits-exhausted-banner-subtitle">
                    You have used up all your credits. Buy now to add more.
                </div>
                <button 
                className="credits-exhausted-banner-button"
                onClick={() => createStripeOrder()}>
                    <div className="credits-exhausted-banner-button-text">
                    Buy Now
                    </div>
                </button>
            </div>
        </div>
        :
        null
    );
}

export default CreditsBanner;